import { memo, useCallback } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { useDebounce } from '$state';
import useDeepCompareEffect from '@/utils/deep-compare-effect';

interface AutoSaveProps {
    defaultValues: object;
    onSubmit: (data: any) => void;
    debounceMs?: number;
}

export const AutoSave = memo(
    ({ defaultValues, onSubmit, debounceMs = 500 }: AutoSaveProps) => {
        // Get the closest form methods
        const methods = useFormContext();

        // Save if this function is called and then not called again within 1000ms
        // eslint-disable-next-line
        const debouncedSave = useCallback(
            useDebounce(debounceMs, () => {
                methods.handleSubmit(onSubmit)();
            }),
            [],
        );

        // // Watch all the data, provide with defaultValues from server, this way we know if the new data came from server or where actually edited by user
        // const watchedData = methods.watch(undefined, defaultValues);
        const watchedData = useWatch({
            control: methods.control,
            defaultValue: defaultValues,
        });

        useDeepCompareEffect(() => {
            if (methods.formState.isDirty) {
                debouncedSave();
            }
        }, [watchedData, methods.formState.isDirty]);

        return null;
    },
);
