import React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

export const Separator = () => {
    return (
        <SeparatorContainer />
    );
};

const SeparatorContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        rgba(0, 0, 0, 0) calc(60% - 1px),
        currentcolor calc(60%),
        rgba(0, 0, 0, 0) calc(60% + 1px)
    );
    ${mix.type({ level: 'h3' })};
    ${mix.color({ profile: 'body' })};
    ${mix.margin({ margin: [2, 0] })};
    opacity: 0.25;
    height: 4px;
`;
