import {
    useAppDispatch,
    useAppSelector,
    useContextLoader,
    withState,
} from '$state';
import {
    isOnGoingCommunication,
    selectReplyBar,
} from '$state/queries/timeline';
import {
    sendComm,
    sendCommApproved,
    takeNote,
    updateReplyState,
} from '$state/concerns/timeline';
import { v4 as uuid } from 'uuid';
import {
    EmailPayload,
    NotePayload,
    ReplyState,
    SMSPayload,
    WhatsAppPayload,
} from '$state/types';
import { CommType } from '$types';
import { CommType as CommTypeV2 } from '$types/timeline-v2';
import {
    selectEnabledChannelKeys,
    selectOngoingChannels,
    selectWritableChannels,
} from '$state/concerns/channels';
import { isNumberValid } from '$state/queries/patient';
import { selectContext } from '$state/concerns/contexts';
import * as React from 'react';
import { ConnectedReplyBar } from '$ui/ReplyBar/ConnectedReplyBar';
import { emailEnabled, smsEnabled } from '$state/queries/client';
import { selectSnooze } from '$state/queries/snooze';
import { whatsAppEnabled } from '$state/queries/integrations';
import { featureEnabled } from '$state/queries/features';
import styled from 'styled-components';
import { Extender } from '$ui/ReplyBar/Extender';
import { Template } from '$state/types/templates';
import { useEffect, useRef } from 'react';

const KEY = 'leadflo__replaybar__state';

interface Props {
    patientId: string;
}

export const TimelineReplyBar = ({ patientId }: Props) => {
    const dispatch = useAppDispatch();

    const snooze = useAppSelector(withState(selectSnooze, patientId));

    const whatsAppFeatureEnabled = useAppSelector(
        withState(featureEnabled, 'whatsapp'),
    );
    const timelineV2Enabled = useAppSelector(
        withState(featureEnabled, 'timeline-v2'),
    );

    // selects enabled channels based on rules
    const channels = useAppSelector(
        withState(selectWritableChannels, {
            SMS: useAppSelector(smsEnabled),
            Email: useAppSelector(emailEnabled),
            WhatsApp: useAppSelector(whatsAppEnabled) && whatsAppFeatureEnabled,
        }),
    );

    const enabledChannels = useAppSelector(
        withState(selectEnabledChannelKeys, {
            SMS: useAppSelector(isNumberValid(patientId)),
            WhatsApp: useAppSelector(isNumberValid(patientId)),
        }),
    );

    const stalledChannels = useAppSelector(
        withState(selectOngoingChannels, {
            WhatsApp: !useAppSelector(
                isOnGoingCommunication(patientId, [
                    CommType.WhatsApp,
                    CommTypeV2.WhatsAppMessage,
                ]),
            ),
        }),
    );

    const state = useAppSelector(selectReplyBar(patientId, enabledChannels));

    const resetReplyState = () => {
        dispatch(
            updateReplyState({
                patientId,
                state: {
                    channel: state.channel,
                    fields: {},
                },
            }),
        );
    };

    const send = () => {
        // TODO: Refactor Channels/CommType enums and make this more generic
        switch (state.channel) {
            case 'note':
                dispatch(
                    takeNote({
                        id: uuid(),
                        patientId,
                        ...(state.fields as NotePayload),
                    }),
                );
                break;
            case 'email':
                dispatch(
                    sendComm({
                        id: uuid(),
                        patientId,
                        channel: CommType.Email,
                        payload: state.fields as EmailPayload,
                        endSnooze: state.endSnooze,
                        templateId: state.templateId,
                        version: timelineV2Enabled ? 2 : 1,
                    }),
                );
                break;
            case 'sms':
                dispatch(
                    sendComm({
                        id: uuid(),
                        patientId,
                        channel: CommType.SMS,
                        payload: state.fields as SMSPayload,
                        endSnooze: state.endSnooze,
                        templateId: state.templateId,
                        version: timelineV2Enabled ? 2 : 1,
                    }),
                );
                break;
            case 'WhatsApp': {
                const fields = state.fields as WhatsAppPayload;
                dispatch(
                    sendComm({
                        id: uuid(),
                        patientId,
                        channel: CommType.WhatsApp,
                        payload: fields,
                        endSnooze: state.endSnooze,
                        templateId: state.templateId,
                        version: timelineV2Enabled ? 2 : 1,
                    }),
                );
                break;
            }
        }

        resetReplyState();
    };

    const sendTemplate = (template: Template, message: string) => {
        switch (state.channel) {
            case 'WhatsApp': {
                const fields = {
                    message,
                    template,
                } as WhatsAppPayload;

                dispatch(
                    sendCommApproved({
                        id: uuid(),
                        patientId,
                        template_id: template.id,
                        channel: CommType.WhatsApp,
                        payload: fields,
                        endSnooze: state.endSnooze,
                        version: timelineV2Enabled ? 2 : 1,
                    }),
                );
                break;
            }
        }

        resetReplyState();
    };

    const onChange = (state: ReplyState) => {
        dispatch(
            updateReplyState({
                patientId,
                state,
            }),
        );
    };

    useContextLoader(patientId);

    const templateContext = useAppSelector(selectContext(patientId));

    const containerRef = useRef<HTMLDivElement>(null);
    const defaultContainerHeight = parseInt(localStorage.getItem(KEY) || '');
    const [containerHeight, setContainerHeight] =
        React.useState<number>(defaultContainerHeight || 200); // in px

    useEffect(() => {
        localStorage.setItem(KEY, containerHeight.toString());
    }, [containerHeight]);

    return (
        <>
            <Extender
                onDrag={(height) => setContainerHeight(height)}
                containerRef={containerRef}
            />
            <Container
                ref={containerRef}
                height={containerHeight}
            >
                <ConnectedReplyBar
                    channels={channels}
                    send={send}
                    sendTemplate={sendTemplate}
                    enabledChannels={enabledChannels}
                    stalledChannels={stalledChannels}
                    templateContext={templateContext}
                    state={state}
                    onStateChanged={onChange}
                    snooze={snooze}
                    containerHeight={containerHeight}
                />
            </Container>
        </>
    );
};

interface ContainerProps {
    height: number;
}

const Container = styled.div<ContainerProps>`
    box-shadow: 0 0 16px 0 #00000014;
    position: relative;
    max-height: 100%;
    height: ${({ height }) => height}px;
`;
