import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormGrid } from '$ui/Settings/Forms/FormGrid';
import { StageField } from '$ui/Settings/Pipeline/CallbackDelay/StageField';
import { Separator } from '$ui/Settings/Pipeline/CallbackDelay/Separator';
import { DelayMap } from '$ui/Settings/Pipeline/hooks';
import { convertDaysAndHoursToSeconds, convertSecondsToDaysAndHours } from '@/utils/date';
import { AutoSave } from '@/utils/auto-save';

interface CallbackDelayFormProps {
    stages: DelayMap;
    onSave: (data: DelayMap) => void;
}

type Fields = {
    newLead: { days: number, hours: number },
    callback1: { days: number, hours: number },
    callback2: { days: number, hours: number },
    working: { days: number, hours: number },
    thinking: { days: number, hours: number },
    lost: { days: number, hours: number },
    maybeFuture: { days: number, hours: number },
    wrongNumber: { days: number, hours: number }
}

export const CallBackDelaysForm = ({ stages, onSave }: CallbackDelayFormProps) => {

    const handleSave = (data: Fields) => {
        const newStages: DelayMap = {
            newLead: {
                stage: 'newLead',
                seconds: convertDaysAndHoursToSeconds(data.newLead.days, data.newLead.hours)
            },
            callback1: {
                stage: 'callback1',
                seconds: convertDaysAndHoursToSeconds(data.callback1.days, data.callback1.hours)
            },
            callback2: {
                stage: 'callback2',
                seconds: convertDaysAndHoursToSeconds(data.callback2.days, data.callback2.hours)
            },
            working: {
                stage: 'working',
                seconds: convertDaysAndHoursToSeconds(data.working.days, data.working.hours)
            },
            thinking: {
                stage: 'thinking',
                seconds: convertDaysAndHoursToSeconds(data.thinking.days, data.thinking.hours)
            },
            lost: {
                stage: 'lost',
                seconds: convertDaysAndHoursToSeconds(data.lost.days, data.lost.hours)
            },
            maybeFuture: {
                stage: 'maybeFuture',
                seconds: convertDaysAndHoursToSeconds(data.maybeFuture.days, data.maybeFuture.hours)
            },
            wrongNumber: {
                stage: 'wrongNumber',
                seconds: convertDaysAndHoursToSeconds(data.wrongNumber.days, data.wrongNumber.hours)
            },
        }

        onSave(newStages);
    };

    const formRef = React.useRef<HTMLFormElement>(null);
    const defaultValues = {
        newLead: convertSecondsToDaysAndHours(stages.newLead.seconds),
        callback1: convertSecondsToDaysAndHours(stages.callback1.seconds),
        callback2: convertSecondsToDaysAndHours(stages.callback2.seconds),
        working: convertSecondsToDaysAndHours(stages.working.seconds),
        thinking: convertSecondsToDaysAndHours(stages.thinking.seconds),
        lost: convertSecondsToDaysAndHours(stages.lost.seconds),
        maybeFuture: convertSecondsToDaysAndHours(stages.maybeFuture.seconds),
        wrongNumber: convertSecondsToDaysAndHours(stages.wrongNumber.seconds),
    }


    const form = useForm<Fields>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues
    });

    return <FormProvider {...form}>
        <form ref={formRef}>
            <FormGrid>
                <StageField name="newLead" label="1st call attempt" />
                <StageField name="callback1" label="2nd call attempt" />
                <StageField name="callback2" label="3rd call attempt" />
                <Separator />
                <StageField name="working" label="When in discussion" />
                <StageField name="thinking" label="When considering tx" />
                <StageField name="lost" label="When reactivating" />
                <Separator />
                <StageField name="wrongNumber" label="From wrong number" />
            </FormGrid>
            <AutoSave
                defaultValues={defaultValues}
                onSubmit={handleSave}
                debounceMs={100}
            />
        </form>
    </FormProvider>;
};
