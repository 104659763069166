import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

interface StageFieldProps {
    name: string;
    label: string;
}

export const StageField = ({ name, label }: StageFieldProps) => {
    const form = useFormContext();

    const focusInput = (fieldName: string) => {
        form.setFocus(fieldName);
    };

    return (
        <InputWrapper>
            <Label key={name} htmlFor={name}>
                {label}
            </Label>
            <Fields>
                <Controller
                    key={`${name}.days`}
                    name={`${name}.days`}
                    control={form.control}
                    rules={{
                        required: 'Something is required',
                        pattern: {
                            value: /^[0-9]+$/,
                            message: 'It has to be a positive number',
                        },
                    }}
                    render={({ field }) => {
                        return (
                            <>
                                <LabelWrapper>
                                    <Input
                                        {...field}
                                        data-cy={field.name}
                                        disabled={field.disabled}
                                        placeholder="Days"
                                        hasError={!!form.formState.errors?.name}
                                    />
                                    <InputLabel
                                        onClick={() => focusInput(field.name)}
                                    >
                                        days
                                    </InputLabel>
                                </LabelWrapper>
                            </>
                        );
                    }}
                />
                :
                <Controller
                    key={`${name}.hours`}
                    name={`${name}.hours`}
                    control={form.control}
                    rules={{
                        required: 'Something is required',
                        pattern: {
                            value: /^[0-9]+$/,
                            message: 'It has to be a positive number',
                        },
                    }}
                    render={({ field }) => {
                        return (
                            <>
                                <LabelWrapper>
                                    <Input
                                        {...field}
                                        data-cy={field.name}
                                        disabled={field.disabled}
                                        placeholder="Hours"
                                        hasError={!!form.formState.errors?.name}
                                    />
                                    <InputLabel
                                        onClick={() => focusInput(field.name)}
                                    >
                                        hours
                                    </InputLabel>
                                </LabelWrapper>
                            </>
                        );
                    }}
                />
            </Fields>
        </InputWrapper>
    );
};

const InputWrapper = styled.div`
    ${mix.gap({ size: 1 })}
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Label = styled.label`
    position: relative;
    ${mix.type({ level: 'body2', bold: true })}
    font-size: 14px;
    color: var(--gray-600);
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 0.5 })};
    min-width: 100px;
`;

const LabelWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const InputLabel = styled.label`
    position: absolute;
    right: 8px;
    color: hsla(218, 15%, 65%, 0.8);
    flex: 1 0 auto;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
`;

const Input = styled.input<{ hasError?: boolean }>`
    ${mix.padding({ padding: 1.5 })};
    border: 1px solid var(--gray-300);
    border-radius: 4px;
    width: 100%;
    max-width: 150px;
    ${({ hasError }) => hasError && `border-color: red;`}
`;

const Fields = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${mix.gap({ size: 1 })};
`;
