import React from 'react';
import { ConnectedFilterbar } from './ConnectedFilterbar';
import * as api from '$api/patients';
import { useAppDispatch, useAppSelector } from '$state/hooks';
import { selectChanged, selectQuery, updateQuery } from '$state/concerns/patient-filter';
import { Patient } from '$types/patients';
import { Footer } from './Footer';
import styled from 'styled-components';
import { AsyncStatus } from '$ui/Flo/types';
import { ConnectedContent } from './Content';
import { mix } from '$ui/Flo/util';
import { selectStages } from '$state/concerns/stages';
import { array, lang } from '$utils';
import { BulkActionBarV2 } from '$ui/Patients/BulkActionBarV2';
import { error as toastError, success as toastSuccess } from '$ui/Flo/ToastV2';
import { PatientsExport, PatientsQuery } from '$state/types/patients';
import { ReportQuery } from '$state/types';
interface Props {
    report: string;
}

interface Page {
    patients?: Patient[];
    status: AsyncStatus;
}

type Data = Record<number, Page>;

const PAGE_SIZE = 50;

type QueryForExport = PatientsExport & PatientsQuery & ReportQuery;

/**
 * Searches for and list patients.
 *
 * This component interacts with the API and handles
 * pagination
 */
export const APIPatients = ({ report }: Props) => {
    const dispatch = useAppDispatch();
    const query = useAppSelector(selectQuery);
    const queryChanged = useAppSelector(selectChanged);
    const stages = useAppSelector(selectStages);
    const [total, setTotal] = React.useState<number>(0);
    const [data, setData] = React.useState<Data>({});
    const [status, setStatus] = React.useState<AsyncStatus>('idle');
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);
    const [shouldRun, setShouldRun] = React.useState(false);

    React.useEffect(() => {
        if (report) {
            // We've loaded the report from a URL, so set the report
            // in state
            dispatch(
                updateQuery({
                    report
                })
            );
            setShouldRun(true);
        }
    }, [report]);

    React.useEffect(() => {
        if (queryChanged) {
            setData({});
            setShouldRun(true);
        }
    }, [query, queryChanged]);

    React.useEffect(() => {
        if (!shouldRun) {
            return;
        }
        setStatus('loading');
        load(1).then(() => {
            setStatus('loaded');
        });
        setShouldRun(false);
    }, [shouldRun]);

    // Run loads the report when th button is pressed
    const run = () => {
        setData({});
        setShouldRun(true);
    };

    const load = (page: number, preloadNext = true): Promise<void> => {
        // The page is already loaded, just display it
        if (data[page]?.status == 'loaded') {
            if (preloadNext) {
                setPage(page);
                return load(page + 1, false);
            }

            return Promise.resolve();
        }

        if (!data[page] || data[page].status == 'idle') {
            // The page doesn't exist yet, so we need to stub it
            setData((prev: Data) => ({
                ...prev,
                [page]: { status: 'loading' }
            }));
        }
        if (preloadNext) {
            // If this function is being used to preload the next
            // page, then we don't want to set the current page to that page
            setPage(page);
        }

        const queryWithoutStages = {
            ...query,
            stages: lang.isEqual(query.stages, array.pluck(stages, 'key'))
                ? null
                : query.stages
        };

        return api
            .fetchPatients(queryWithoutStages, PAGE_SIZE, page)
            .then((res) => {
                setData((prev: Data) => ({
                    ...prev,
                    [page]: { patients: res.data, status: 'loaded' }
                }));
                setTotal(res.metadata.total);
                setPages(Math.ceil(res.metadata.total / PAGE_SIZE));
            })
            .then(() => {
                if (preloadNext) {
                    load(page + 1, false);
                }
            })
            .catch((err) => {
                console.error(err);
                setData((prev: Data) => ({
                    ...prev,
                    [page]: { status: 'error' }
                }));
                setStatus('error');
            });
    };

    const runExport = () => {
        const report = query.report && query.report.split('/');
        const queryWithoutStages: QueryForExport = {
            ...query,
            stages: lang.isEqual(query.stages, array.pluck(stages, 'key'))
                ? null
                : query.stages,
            type: report && report[0] ? report[0] : 'unknown',
        };

        api.exportPatients(queryWithoutStages)
            .then(() => {
                toastSuccess({
                    id: 'patient-export-started',
                    message: 'Your export has started! You will receive an email when it\'s ready.',
                });
            })
            .catch(() => {
                toastError({
                    id: 'patient-export-error',
                    message: 'Oops. Something went wrong!',
                });
            })
    };

    return (
        <Container>
            <ConnectedFilterbar run={run} runExport={runExport} />
            <ConnectedContent
                status={data[page]?.status ?? 'idle'}
                data={data[page]?.patients ?? []}
            />
            <FooterPosition>
                <Footer
                    pages={pages}
                    page={page}
                    onPageChange={(page) => load(page)}
                    status={status}
                />
            </FooterPosition>
            {status === 'loaded' && (
                <BulkActionsContainer>
                    <BulkActionBarV2 total={total} />
                </BulkActionsContainer>
            )}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    height: calc(100vh - 54px);
    display: flex;
    flex-direction: column;
    padding-bottom: 46px;
`;

const FooterPosition = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100vw - 224px); // See src/ui/Layout/Content.tsx
`;

const BulkActionsContainer = styled.div`
    position: absolute;
    z-index: 200;
    width: 50vw;
    min-width: ${mix.unit({ size: 86 })};
    bottom: ${mix.unit({ size: 11 })};
    left: 50%;
    transform: translateX(-50%);
`;
