import * as React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { VersionedRoute } from './router/react';
import { store, useAppDispatch, useAppSelector } from '$state';
import { fetchCounts } from '$state/concerns/actions';
import { init } from '$state/concerns/auth';
import { actionsRoutes } from '$state/queries/actions';
import { Content } from '$ui/Layout/Content';
import { Theme } from '$ui/Theme';
import { Actions } from './views/Actions';
import { Login } from './views/Login';
import { Editor as PatientEditor } from './ui/Patient';
import { inboxRoutes } from '$state/queries/inbox';
import { Inbox } from './views/Inbox';
import { hydrateConversations } from '$state/concerns/inbox';
import { Reporting } from './views/Reporting';
import { Automations } from './views/Automations';
import { Automation } from './views/Automation';
import { ActionEditor } from '$ui/Automations/ActionEditor';
import { Settings } from './views/Settings';
import { Patients } from './views/Patients';
import { getSettings, selectSettings } from '$state/concerns/client';
import { Toasts } from '$ui/Flo/ToastV2';
import { RequireAuth, useAuthentication, withRequireAuth } from '@/auth/react';
import { ConnectionStatus } from '$ui/Integrations/ConnectionStatus';
import * as integrations from '$state/concerns/integrations';
import { lang } from '$utils';
import { Palette } from '$ui/Theme/colors';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { useIsConfigured } from '@/config/react';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const App = () => {
    const dispatch = useAppDispatch();
    const configured = useIsConfigured();
    const settings = useAppSelector(selectSettings);
    const authed = useAuthentication();
    const integrationsStatus = useAppSelector(integrations.selectStatus);
    const actionsDue = useAppSelector(actionsRoutes, lang.isEqual);

    const actions = actionsDue.map((route) => (
        <VersionedRoute key={route.path} path={route.path}>
            <RequireAuth>
                <Actions title={route.title} />
            </RequireAuth>
        </VersionedRoute>
    ));

    const inbox = useAppSelector(inboxRoutes, lang.isEqual).map((route) => (
        <VersionedRoute key={route.path} path={route.path} exact>
            <RequireAuth>
                <Inbox {...route} />
            </RequireAuth>
        </VersionedRoute>
    ));

    React.useEffect(() => {
        if (configured && authed) {
            dispatch(init());
            dispatch(fetchCounts([]));
            dispatch(hydrateConversations());
        }
    }, [configured, authed]);

    React.useEffect(() => {
        if (!configured || !authed) {
            return;
        }

        if (settings.practice.status === 'idle') {
            dispatch(getSettings('practice'));
        }

        if (settings.assets.status === 'idle') {
            dispatch(getSettings('assets'));
        }
    }, [configured, authed, settings.assets.status, settings.practice.status]);

    React.useEffect(() => {
        if (!configured || !authed) {
            return;
        }

        if (integrationsStatus === 'init' || integrationsStatus === 'errored') {
            dispatch(integrations.load());
        }
    }, [configured, authed, integrationsStatus]);

    if (!configured) {
        // Need some kind of initial app loading state?
        return <div></div>;
    }

    if (!authed) {
        return (
            <Theme>
                <Login />
            </Theme>
        );
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Theme>
                <Palette />
                <Router>
                    <PatientEditor />
                    <ActionEditor />
                    <Switch>
                        <VersionedRoute exact path="/integrations/:status">
                            <ConnectionStatus />
                        </VersionedRoute>
                        <VersionedRoute path="/">
                            <Content>
                                <Switch>
                                    <VersionedRoute path="/reporting">
                                        <RequireAuth>
                                            <Reporting />
                                        </RequireAuth>
                                    </VersionedRoute>

                                    <VersionedRoute
                                        path="/automations/:id"
                                        component={withRequireAuth(Automation)}
                                    />

                                    <VersionedRoute path="/automations">
                                        <RequireAuth>
                                            <Automations />
                                        </RequireAuth>
                                    </VersionedRoute>

                                    <VersionedRoute path="/settings">
                                        <RequireAuth>
                                            <Settings />
                                        </RequireAuth>
                                    </VersionedRoute>

                                    <VersionedRoute
                                        path="/patients/:namespace/:report"
                                        component={withRequireAuth(Patients)}
                                    />

                                    {inbox}
                                    {actions}
                                </Switch>
                            </Content>
                        </VersionedRoute>
                    </Switch>
                </Router>
                <Toasts />
            </Theme>
        </QueryClientProvider>
    );
};

export const ReactApp = () => {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    );
};
