import { useMutation, useQuery } from '@tanstack/react-query';
import { api } from '$api';

export type DidNotAnswerStages =
    | 'newLead'
    | 'callback1'
    | 'callback2'
    | 'working'
    | 'thinking'
    | 'lost'
    | 'maybeFuture'
    | 'wrongNumber';

export type Delay = {
    stage: string;
    seconds: number;
};

export type DelayMap = {
    [Name in DidNotAnswerStages]: Delay;
};

export type Settings = {
    call_back_delays: DelayMap;
};

export const queryKey = ['pipeline-settings'];

export const usePipelineSettings = () => {
    return useQuery({
        queryKey,
        queryFn: () => {
            return api
                .get<Settings>('/v3/clients/settings/pipeline')
                .then(({ data }) => data);
        }
    });
};

export const usePipelineSettingsMutation = () => {
    return useMutation({
        mutationFn: async (settings: Settings) => {
            const { data } = await api
                .put<Settings>('/v3/clients/settings/pipeline', settings);
            return data;
        },
        retry: 3
    });
};
