import React, { useEffect } from 'react';
import { usePageTitle } from '@/utils/hooks';
import { Section } from '$ui/Settings/Section';
import { Subsection } from '$ui/Settings/Section/Subsection';
import { CallBackDelaysForm } from '$ui/Settings/Pipeline/CallbackDelays';
import { DelayMap, Settings, usePipelineSettings, usePipelineSettingsMutation } from '$ui/Settings/Pipeline/hooks';
import { Loading } from '$ui/Settings/Pipeline/CallbackDelay/Loading';
import { success as toastSuccess } from '$ui/Flo/ToastV2';
import { SettingStatus } from '$state/types/client';

export const Pipeline = () => {
    usePageTitle('Pipeline - Settings');

    const { isFetched, isLoading, isRefetching, data } = usePipelineSettings();
    const pipelineSettingsMutation = usePipelineSettingsMutation();

    const handleSave = (data: DelayMap) => {
        const updateData: Settings = { call_back_delays: data };
        pipelineSettingsMutation.mutate(updateData);
    };

    useEffect(() => {
        if (pipelineSettingsMutation.isSuccess) {
            toastSuccess({
                id: SettingStatus.SAVED,
                message: 'Pipeline setting successfully saved!',
            });
        }
    }, [pipelineSettingsMutation.isSuccess]);

    return (
        <Section title="Pipeline">
            <Subsection id="call-back-delays" title="Call back delays">
                {(isLoading || isRefetching) && <Loading />}
                {(isFetched && !isRefetching) && data && <CallBackDelaysForm stages={data.call_back_delays} onSave={handleSave} />}
            </Subsection>
        </Section>
    );
};
