import React from 'react';
import * as stages from '$state/concerns/stages';
import { useAppDispatch, useAppSelector } from '$state';
import { Cell, Frame, Grid, Subtitle } from '$ui/Reporting/Layout';
import { loadOverviewReport } from '$state/concerns/reporting';
import { selectChanged, selectQuery } from '$state/concerns/patient-filter';
import {
    ConnectedBrokenMetric,
    ConnectedMetric,
} from '$ui/Reporting/ConnectedMetric';
import { usePageTitle } from '@/utils/hooks';

export const PracticeOverview = () => {
    const dispatch = useAppDispatch();
    const query = useAppSelector(selectQuery);
    const queryChanged = useAppSelector(selectChanged);

    usePageTitle('Practice Overview - Reporting');

    React.useEffect(() => {
        if (queryChanged) {
            dispatch(loadOverviewReport(query));
        }
    }, [query, queryChanged]);

    const runReport = () => {
        dispatch(loadOverviewReport(query));
    };

    React.useEffect(runReport, []);

    React.useEffect(() => {
        dispatch(stages.load());
    }, []);

    return (
        <>
            <Frame
                run={runReport}
                title="Practice Overview"
                description={description}
            >
                <Subtitle>Conversions</Subtitle>
                <Grid>
                    <Cell>
                        <ConnectedMetric
                            id="overview/consultsBooked"
                            report="overview"
                            name="consults_booked"
                            label="Consults booked"
                            fill
                        />
                    </Cell>
                    <Cell>
                        <ConnectedMetric
                            id="overview/goneAhead"
                            report="overview"
                            name="gone_ahead"
                            label="Gone ahead"
                            fill
                        />
                    </Cell>
                </Grid>
                <Subtitle>Conversations</Subtitle>
                <Grid>
                    <Cell>
                        <ConnectedMetric
                            id={null}
                            report="overview"
                            name="email_received"
                            label="Emails received"
                        />
                    </Cell>
                    <Cell>
                        <ConnectedMetric
                            id={null}
                            report="overview"
                            name="email_sent"
                            label="Emails sent"
                        />
                    </Cell>
                    <Cell>
                        <ConnectedMetric
                            id={null}
                            report="overview"
                            name="sms_received"
                            label="SMS received"
                        />
                    </Cell>
                    <Cell>
                        <ConnectedMetric
                            id={null}
                            report="overview"
                            name="sms_sent"
                            label="SMS sent"
                        />
                    </Cell>
                    <Cell>
                        <ConnectedMetric
                            id="overview/leadsReceived"
                            report="overview"
                            name="leads_received"
                            label="Leads received"
                        />
                    </Cell>
                    <Cell span={2}>
                        <ConnectedMetric
                            id={null}
                            report="overview"
                            name="avg_initial_contact_secs"
                            label="Average time to contact patient"
                            unit="interval"
                        />
                    </Cell>
                </Grid>
                <Subtitle>Losses</Subtitle>
                <Grid>
                    <Cell>
                        <ConnectedBrokenMetric
                            id="overview/losses"
                            report="overview"
                            name="losses"
                            label="Losses"
                            hue="red"
                            tooltip="Total losses do not include patients you have blocked"
                            tooltipAlign="center"
                        />
                    </Cell>
                    <Cell>
                        <ConnectedMetric
                            id="overview/didNotAttend"
                            report="overview"
                            name="did_not_attend"
                            label="Failed to Attend"
                            fill
                            hue="red"
                        />
                    </Cell>
                </Grid>
            </Frame>
        </>
    );
};

const description = `
    The Practice Overview report provides all the numbers you need to the manage
    your team day-to-day. Use these numbers to see how well the team performs on
    a daily, weekly and monthly basis.
`;
