import React from 'react';
import * as Sections from './Settings/index';
import { Tab, TabItemProps } from '$ui/Settings/Tab';
import {
    useAppSelector,
    usePlaceholdersLoader,
    useTemplateLoader,
} from '$state/hooks';
import { anyIntegrationUnhealthy } from '$state/concerns/integrations';
import { withState } from '$state';
import { featureEnabled } from '$state/queries/features';

export const Settings = () => {
    const templatesV2FeatureEnabled = useAppSelector(
        withState(featureEnabled, 'templates-v2'),
    );

    const pipelineSettingsEnabled = useAppSelector(
        withState(featureEnabled, 'pipeline-settings'),
    );

    const def: Array<TabItemProps> = [
        {
            icon: 'Compass',
            key: 'practice',
            name: 'Practice',
            items: [
                { key: 'details', name: 'Details' },
                { key: 'practice-email', name: 'Practice Email' },
                { key: 'signature', name: 'Signature' },
                { key: 'branding', name: 'Brand' },
                { key: 'types', name: 'Types' },
            ],
            exact: true,
            component: templatesV2FeatureEnabled
                ? Sections.PracticeV2
                : Sections.Practice,
        },
        {
            icon: 'Layers',
            key: 'templates',
            name: 'Templates',
            component: templatesV2FeatureEnabled
                ? Sections.TemplatesV2
                : Sections.Templates,
        },
    ];
    usePlaceholdersLoader();
    useTemplateLoader();

    const integrationAlert = useAppSelector(anyIntegrationUnhealthy);

    return (
        <Tab
            items={[
                ...def,
                {
                    icon: 'GitPullRequest',
                    key: 'integrations',
                    name: 'Integrations',
                    component: Sections.Integrations,
                    alert: integrationAlert,
                },
                {
                    icon: 'MessageSquare',
                    key: 'communications',
                    name: 'Communications',
                    component: Sections.Communications,
                    items: [
                        { key: 'sending-email', name: 'Sending Email' },
                        {
                            key: 'email-verifications',
                            name: 'Email Verifications',
                        },
                        { key: 'email-testing', name: 'Email Testing' },
                        { key: 'sms', name: 'SMS' },
                        { key: 'sms-autoresponder', name: 'SMS Autoresponder' },
                    ],
                    exact: true,
                },

                ...(pipelineSettingsEnabled
                    ? [
                          {
                              icon: 'GitBranch',
                              key: 'pipeline',
                              name: 'Pipeline',
                              component: Sections.Pipeline,
                              exact: true,
                          } as const,
                      ]
                    : []),
            ]}
            path="settings"
            active="practice"
        />
    );
};
