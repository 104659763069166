// This code is adapted from https://github.com/lukeed/dequal
const has = Object.prototype.hasOwnProperty;

function find<T extends Set<unknown> | Map<unknown, unknown>>(
    iter: T,
    tar: object,
) {
    for (const key of iter.keys()) {
        if (dequal(key, tar)) return key;
    }

    return undefined;
}

export function dequal(foo: unknown, bar: unknown): boolean {
    let ctor, len, tmp;
    if (foo === bar) return true;

    if (foo && bar && (ctor = foo.constructor) === bar.constructor) {
        if (foo instanceof Date && bar instanceof Date)
            return foo.getTime() === bar.getTime();
        if (foo instanceof RegExp && bar instanceof RegExp)
            return foo.toString() === bar.toString();

        if (foo instanceof Array && bar instanceof Array) {
            if ((len = foo.length) === bar.length) {
                while (len-- && dequal(foo[len], bar[len]));
            }
            return len === -1;
        }

        if (foo instanceof Set && bar instanceof Set) {
            if (foo.size !== bar.size) {
                return false;
            }
            for (len of foo) {
                tmp = len;
                if (tmp && typeof tmp === 'object') {
                    tmp = find(bar, tmp);
                    if (!tmp) return false;
                }
                if (!bar.has(tmp)) return false;
            }
            return true;
        }

        if (foo instanceof Map && bar instanceof Map) {
            if (foo.size !== bar.size) {
                return false;
            }
            for (len of foo) {
                tmp = len[0];
                if (tmp && typeof tmp === 'object') {
                    tmp = find(bar, tmp);
                    if (!tmp) return false;
                }
                if (!dequal(len[1], bar.get(tmp))) {
                    return false;
                }
            }
            return true;
        }

        if (foo instanceof ArrayBuffer && bar instanceof ArrayBuffer) {
            foo = new Uint8Array(foo);
            bar = new Uint8Array(bar);
        } else if (foo instanceof DataView && bar instanceof DataView) {
            if ((len = foo.byteLength) === bar.byteLength) {
                while (len-- && foo.getInt8(len) === bar.getInt8(len));
            }
            return len === -1;
        }

        if (ArrayBuffer.isView(foo) && ArrayBuffer.isView(bar)) {
            if ((len = foo.byteLength) === bar.byteLength) {
                while (len-- && foo[len] === bar[len]);
            }
            return len === -1;
        }

        if (!ctor) {
            if (typeof foo === 'object' && typeof bar === 'object') {
                len = 0;
                for (ctor in foo) {
                    if (has.call(foo, ctor) && ++len && !has.call(bar, ctor))
                        return false;

                    if (
                        (bar !== null && !(ctor in bar)) ||
                        (foo !== null &&
                            bar !== null &&
                            !dequal(foo[ctor], bar[ctor]))
                    )
                        return false;
                }
                return bar !== null && Object.keys(bar).length === len;
            }
        }
    }

    return foo !== foo && bar !== bar;
}
