import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Align, Hue, Shade } from '../types';
import { mix } from '../util';

interface Props {
    /**
     * If `true`, tooltip is open.
     */
    open?: boolean;

    /**
     * Background color hue.
     *
     * @default 'primary'
     */
    hue?: Hue;

    /**
     * Background color shade.
     *
     * @default '5'
     */
    shade?: Shade;

    /**
     * Tooltip content.
     */
    children: React.ReactNode;

    /**
     * If `true`, use light text (for dark backgrounds).
     *
     * @default true
     */
    dark?: boolean;

    /**
     * Horizontal alignment mode of the tooltip.
     *
     * @default 'left'
     */
    align?: Align;

    /**
     * Width of the tooltip.
     */
    width?: number;

    /**
     * Number of milliseconds to wait before signalling to close the tooltip.
     *
     * If `0`, tooltip never signals when to close.
     *
     * @default 5000
     */
    closeDelay?: number;

    /**
     * Size of the tooltip.
     */
    size?: 'x-small' | 'small' | 'medium' | 'large';

    /**
     * A callback that is invoked when the Tooltip is closing.
     */
    onClose?: () => void;
    backgroundColor?: string;
    vAlign?: 'top' | 'bottom' | 'center';
}

export const Tooltip = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
        hue = 'primary',
        shade = '6',
        dark = true,
        align = 'left',
        closeDelay = 5000,
        onClose = () => null,
        width,
        open,
        children,
        size = 'medium',
        backgroundColor,
        vAlign = 'top',
    } = props;
    const containerProps = {
        open,
        hue,
        shade,
        dark,
        align,
        width,
        containerSize: size,
        backgroundColor,
        vAlign,
    };

    React.useEffect(() => {
        if (!open) {
            return;
        }

        if (closeDelay === 0) {
            return;
        }

        if (!onClose) {
            return;
        }

        setTimeout(() => {
            onClose();
        }, closeDelay);
    }, [open]);

    const triangleSize = () => {
        switch (size) {
            case 'x-small':
                return 0.5;
            case 'small':
                return 1;
            case 'large':
                return 3;
            default:
                return 2;
        }
    };

    return (
        <Container ref={ref} {...containerProps} size={triangleSize()}>
            <div
                style={{
                    whiteSpace: width ? 'normal' : 'nowrap',
                    textAlign: align === 'center' ? 'center' : 'left',
                }}
            >
                {children}
            </div>
            <Triangle {...containerProps} size={triangleSize()} />
        </Container>
    );
});

const positionRight = (size: string | undefined) => {
    switch (size) {
        case 'x-small':
            return css`
                ${mix.unit({ size: 0.5 })}
            `;
        default:
            return css`-${mix.unit({ size: 1 })}`;
    }
};

const positionLeft = (size: string | undefined) => {
    switch (size) {
        case 'x-small':
            return css`-${mix.unit({ size: 0.5 })}`;
        default:
            return css`-${mix.unit({ size: 1 })}`;
    }
};

interface ContainerProps {
    open?: boolean;
    hue: Hue;
    shade: Shade;
    align: Align;
    width?: number;
    containerSize?: 'x-small' | 'small' | 'medium' | 'large';
    backgroundColor?: string;
    vAlign?: 'top' | 'bottom' | 'center';
    size: number;
}

const SizeExtraSmall = css`
    ${mix.padding({ padding: 1 })};
    ${mix.type({ level: 'small' })};
    top: -${mix.unit({ size: 1 })};
`;

const SizeSmall = css`
    ${mix.padding({ padding: 1 })};
    ${mix.type({ level: 'small' })};

    top: -${mix.unit({ size: 2 })};
`;

const SizeMedium = css`
    ${mix.padding({ padding: 2 })};
    ${mix.type({ level: 'body2' })};
    top: -${mix.unit({ size: 3 })};
`;

const SizeLarge = css`
    ${mix.padding({ padding: 3 })};
    ${mix.type({ level: 'body1' })};
    top: -${mix.unit({ size: 4 })};
`;

const Container = styled.div<ContainerProps>`
    ${({ backgroundColor }) =>
        css`
            background: ${backgroundColor} !important;
        `};
    ${mix.bg()};
    ${({ containerSize }) => {
        switch (containerSize) {
            case 'x-small':
                return SizeExtraSmall;
            case 'small':
                return SizeSmall;
            case 'large':
                return SizeLarge;
            default:
                return SizeMedium;
        }
    }};

    color: #fff;
    position: absolute;
    border-radius: 4px;
    transition: transform 0.2s ease-in-out;
    pointer-events: none;

    ${({ width }) =>
        width &&
        css`
            width: ${mix.unit({ size: width })};
        `};

    ${({ align, open, containerSize, vAlign, size }) => {
        if (vAlign === 'bottom') {
            if (align === 'center') {
                return css`
                    left: 50%;
                    transform: translate(-50%, 100%) scale(0);
                    transform-origin: bottom center;
                    bottom: -${mix.unit({ size: size })};
                    top: auto;
                    ${open &&
                    css`
                        transform: translate(-50%, 100%) scale(1);
                    `};
                `;
            }

            if (align === 'right') {
                return css`
                    right: ${positionRight(containerSize)};
                    transform: translate(${mix.unit({ size: size })}, 100%) scale(0);
                    transform-origin: bottom right;
                    bottom: -${mix.unit({ size: size })};
                    top: auto;
                    ${open &&
                    css`
                        transform: translate(0, 100%) scale(1);
                    `};
                `;
            }

            return css`
                left: ${positionLeft(containerSize)};
                transform: translate(0, 100%) scale(0);
                bottom: -${mix.unit({ size: size })};
                top: auto;
                ${open &&
                css`
                    transform: translate(0, 100%) scale(1);
                `};
            `;
        }
        if (vAlign === 'center') {
            if (align === 'center') {
                return css`
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%) scale(0);
                    transform-origin: bottom center;

                    ${open &&
                    css`
                        transform: translate(-50%, -100%) scale(1);
                    `};
                `;
            }

            if (align === 'right') {
                return css`
                    left: calc(100% + ${mix.unit({ size: size })});
                    transform: translateY(-50%) scale(0);
                    transform-origin: center center;
                    top: 50%;
                    ${open &&
                    css`
                        transform: translateY(-50%) scale(1);
                    `};
                `;
            }

            return css`
                right: calc(100% + ${mix.unit({ size: size })});
                transform: translateY(-50%) scale(0);
                top: 50%;
                ${open &&
                css`
                    transform: translateY(-50%) scale(1);
                `};
            `;
        }

        if (align === 'center') {
            return css`
                left: 50%;
                transform: translate(-50%, -100%) scale(0);
                transform-origin: bottom center;
                top: -${mix.unit({ size: size })};
                ${open &&
                css`
                    transform: translate(-50%, -100%) scale(1);
                `};
            `;
        }

        if (align === 'right') {
            return css`
                right: ${positionRight(containerSize)};
                transform: translate(${mix.unit({ size: size })}, -100%) scale(0);
                top: -${mix.unit({ size: size })};
                ${open &&
                css`
                    transform: translate(${mix.unit({ size: size })}, -100%)
                        scale(1);
                `};
            `;
        }

        return css`
            left: ${positionLeft(containerSize)};
            transform: translateY(-100%) scale(0);
            top: -${mix.unit({ size: size })};
            ${open &&
            css`
                transform: translateY(-100%) scale(1);
            `};
        `;
    }}
`;

interface TriangleProps {
    size: number;
}

const Triangle = styled.div<ContainerProps & TriangleProps>`
    position: absolute;
    width: 0;
    height: 0;

    ${({ align, vAlign, backgroundColor, size }) => {
        if (vAlign === 'center') {
            if (align === 'center') {
                return css`
                    left: 50%;
                    transform: translateX(-50%);
                    border-left: ${mix.unit()} solid transparent;
                    border-right: ${mix.unit()} solid transparent;
                    border-top: ${mix.unit()} solid ${backgroundColor ? backgroundColor : mix.palette()};
                    bottom: -${mix.unit()};
                `;
            }

            if (align === 'right') {
                return css`
                    right: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    border-right: ${mix.unit()} solid ${backgroundColor ? backgroundColor : mix.palette()};
                    border-top: ${mix.unit()} solid transparent;
                    border-bottom: ${mix.unit()} solid transparent;
                    bottom: -${mix.unit()};
                `;
            }

            return css`
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
                border-left: ${mix.unit()} solid ${backgroundColor ? backgroundColor : mix.palette()};
                border-top: ${mix.unit()} solid transparent;
                border-bottom: ${mix.unit()} solid transparent;
                bottom: -${mix.unit()};
            `;
        }

        if (vAlign === 'bottom') {
            if (align === 'center') {
                return css`
                    left: 50%;
                    bottom: 100%;
                    border-left: ${mix.unit()} solid transparent;
                    border-right: ${mix.unit()} solid transparent;
                    border-top: ${mix.unit()} solid transparent;
                    border-bottom: ${mix.unit()} solid
                    ${backgroundColor ? backgroundColor : mix.palette()};
                    transform: translateX(-50%) rotate(0deg);
                `;
            }

            if (align === 'right') {
                return css`
                    right: ${mix.unit({ size: 1 })};
                    bottom: 100%;
                    border-left: ${mix.unit()} solid transparent;
                    border-right: ${mix.unit()} solid transparent;
                    border-top: ${mix.unit()} solid transparent;
                    border-bottom: ${mix.unit()} solid
                    ${backgroundColor ? backgroundColor : mix.palette()};
                    transform: rotate(0deg);
                `;
            }

            return css`
                left: ${mix.unit({ size: 1 })};
                bottom: 100%;
                border-left: ${mix.unit()} solid transparent;
                border-right: ${mix.unit()} solid transparent;
                border-top: ${mix.unit()} solid transparent;
                border-bottom: ${mix.unit()} solid
                    ${backgroundColor ? backgroundColor : mix.palette()};
                transform: rotate(0deg);
            `;
        }

        if (align === 'center') {
            return css`
                left: 50%;
                transform: translateX(-50%) rotate(0deg);
                border-left: ${mix.unit()} solid transparent;
                border-right: ${mix.unit()} solid transparent;
                border-top: ${mix.unit()} solid ${backgroundColor ? backgroundColor : mix.palette()};
                bottom: -${mix.unit({ size: size })};
            `;
        }

        if (align === 'right') {
            return css`
                right: ${mix.unit({ size: 1 })};
                transform: translateX(-50%) rotate(0deg);
                border-left: ${mix.unit()} solid transparent;
                border-right: ${mix.unit()} solid transparent;
                border-top: ${mix.unit()} solid ${backgroundColor ? backgroundColor : mix.palette()};
                bottom: -${mix.unit({ size: size })};
            `;
        }

        return css`
            left: ${mix.unit({ size: size })};
            transform:  rotate(0deg);
            border-left: ${mix.unit()} solid transparent;
            border-right: ${mix.unit()} solid transparent;
            border-top: ${mix.unit()} solid ${backgroundColor ? backgroundColor : mix.palette()};
            bottom: -${mix.unit({ size: size })};
        `;
    }}
`;
