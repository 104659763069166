import {
    format,
    formatDistance,
    formatDuration,
    intervalToDuration,
} from 'date-fns';
import { enGB } from 'date-fns/locale';

const locales = {
    'en-GB': enGB,
};

const settings = {
    locale: 'en-GB',
};

export const epoch = new Date('1970-01-01T00:00:00.000Z');
export const setLocale = (locale: string) => {
    settings.locale = locale;
};

export const normalize = (date: string | Date) => {
    if (typeof date === 'string') {
        return new Date(date);
    }
    return date;
};

export const long = (date: string | Date = new Date(), weekday = true) => {
    return new Intl.DateTimeFormat(settings.locale, {
        weekday: weekday ? 'long' : undefined,
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }).format(normalize(date));
};

export const shortDate = (date: string | Date = new Date()) => {
    return format(normalize(date), 'P', {
        locale: locales[settings.locale],
    });
};

export const time = (
    date: string | Date = new Date(),
    formatStr = 'h:mmaaa',
) => {
    return format(normalize(date), formatStr, {
        locale: locales[settings.locale],
    });
};

export const militaryTime = (date: string | Date = new Date()) => {
    return format(normalize(date), 'HH:mm:ss', {
        locale: locales[settings.locale],
    });
};

export const delta = (
    date: string | Date,
    start: string | Date = new Date(),
    includeSeconds = false,
) => {
    return formatDistance(normalize(date), normalize(start), {
        addSuffix: true,
        locale: locales[settings.locale],
        includeSeconds,
    });
};

export const isoDate = (date: string | Date): string => {
    return format(normalize(date), 'yyyy-MM-dd', {
        locale: locales[settings.locale],
    });
};

export const duration = (seconds: number) => {
    if (seconds === 0) {
        return '0s';
    }

    const {
        years = 0,
        months = 0,
        hours = 0,
        days = 0,
        minutes,
        seconds: secs,
    } = intervalToDuration({ start: 0, end: seconds * 1000 });

    return formatDuration(
        {
            days: years * 365 + months * 30 + days,
            hours,
            minutes,
            seconds: secs,
        },
        {
            format: ['days', 'hours', 'minutes', 'seconds'],
            locale: {
                ...locales[settings.locale],
                formatDistance: (token, count) => {
                    const tokens = {
                        xSeconds: 's',
                        xMinutes: 'm',
                        xHours: 'h',
                        xDays: 'd',
                    };

                    return `${count}${tokens[token]}`;
                },
            },
        },
    );
};

export const isoDateTime = (date: string | Date = new Date()): string => {
    return new Date(date).toISOString();
};

export const toDate = (date: string | Date): string => {
    return format(normalize(date), 'dd/MM/yyyy', {
        locale: locales[settings.locale],
    });
};

/**
 * Takes a date and returns the local offset in ISO format. E.g if the local
 * offset is 60 minutes, +0100 will be returned
 *
 * @param date
 * @returns {string}
 */
export const offset = (date: string | Date): string => {
    const offset = normalize(date).getTimezoneOffset();
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    const sign = offset > 0 ? '-' : '+';

    return `${sign}${hours.toString().padStart(2, '0')}${minutes
        .toString()
        .padStart(2, '0')}`;
};

export const convertTo24Hour = (hour: number, meridiem: string): number => {
    return meridiem === 'am' ? hour : 12 + hour;
};

export const hasHourPassed = (currentTime: Date, hour: number) => {
    return currentTime.getHours() > hour;
};

export const hasMinutesPassed = (currentTime: Date, minute: number) => {
    return currentTime.getMinutes() > minute;
};

export const disablePastHours = (
    currentTime: Date | undefined,
    hour: number,
    interval = 15,
) => {
    if (currentTime instanceof Date) {
        if (currentTime.getHours() === hour) {
            return currentTime.getMinutes() > 60 - interval;
        }

        return hasHourPassed(currentTime, hour);
    }

    return false;
};

export const disablePastMinutes = (
    currentTime: Date | undefined,
    hour: number,
    minute: number,
) => {
    if (
        currentTime instanceof Date &&
        (hour === currentTime.getHours() || hasHourPassed(currentTime, hour))
    ) {
        return hasMinutesPassed(currentTime, minute);
    }

    return false;
};

export const disableMeridiemChange = (currentTime: Date | undefined) => {
    if (currentTime instanceof Date) {
        return currentTime.getHours() > 12;
    }

    return false;
};

export const convertSecondsToDaysAndHours = (seconds: number) => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);

    return { days, hours };
};

export const convertDaysAndHoursToSeconds = (days: number, hours: number) => {
    return days * 86400 + hours * 3600;
};
