import { Icon } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import { str } from '$utils';
import React from 'react';
import styled, { css } from 'styled-components';

export interface IntegrationProps {
    [key: string]: IntegrationItemProps;
}

export interface IntegrationItemProps {
    icon: React.ReactNode;
    name: string;
    status: 'linked' | 'unlinked' | 'unknown';
    onClick?: () => void;
}

export interface IntegrationBarProps {
    integrations: IntegrationProps;
    size?: number;
    position?: 'left' | 'right';
    active: string;
    onActiveChange?: (name: string) => void;
}

export const IntegrationBar = (props: IntegrationBarProps) => {
    const { integrations, size, active, onActiveChange, position } = props;

    const [tooltips, setTooltips] = React.useState<{
        [key: string]: boolean;
    }>(
        Object.entries(integrations).reduce((tooltips, [key]) => {
            tooltips[key] = false;
            return tooltips;
        }, {})
    );

    const toggleTooltip = (name: string) => {
        setTooltips((prev) => ({
            ...prev,
            [name]: !tooltips[name]
        }));
    };

    return (
        <Wrapper>
            {Object.entries(integrations).map(([key, value]) => {
                const isActive =
                    value.name.toLowerCase() === active.toLowerCase();

                return (
                    <IntegrationItem
                        data-testid={`integration-${key}`}
                        key={key}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (value.onClick) {
                                value.onClick();
                            }
                            if (onActiveChange) {
                                onActiveChange(value.name);
                            }
                        }}
                        onMouseEnter={() => toggleTooltip(value.name)}
                        onMouseLeave={() => toggleTooltip(value.name)}
                    >
                        <IntegrationStatus
                            data-testid={`integration-${key}-status-${value.status}`}
                            size={size ? size : 1}
                            active={isActive}
                        >
                            {value.status !== 'unknown' && (
                                <IntegrationIcon status={value.status}>
                                    <Icon icon="Link" hue="white" shade="10" />
                                </IntegrationIcon>
                            )}
                            {value.icon}
                        </IntegrationStatus>
                        <Tooltip
                            size={size ? size : 1}
                            show={tooltips[value.name]}
                            position={position}
                        >
                            <TooltipText>
                                {str.capitalize(value.name)}
                                {' - '}
                                {str.capitalize(value.status)}
                            </TooltipText>
                        </Tooltip>
                    </IntegrationItem>
                );
            })}
        </Wrapper>
    );
};

const transformLeft = css<{ size: number }>`
    transform: translateX(-${({ size }) => mix.unit({ size: size * 1.25 })});
    align-self: end;
`;

const transformRight = css<{ size: number }>`
    transform: translateX(${({ size }) => mix.unit({ size: size * 1.25 })});
    align-self: start;
`;

const Tooltip = styled.div<{ size: number; show: boolean; position?: string }>`
    ${mix.bg({ hue: 'primary', shade: '1' })}
    ${mix.type({ level: 'body1' })}
    ${mix.color({ profile: 'title', dark: true })}
    border-radius: ${({ size }) => mix.unit({ size: size / 8 })};
    ${mix.padding({ padding: [0, 2] })}

    ${({ position }) => (position !== 'right' ? transformLeft : transformRight)}

    position: absolute;
    z-index: 9999;
    top: 12.5%;
    bottom: 12.5%;

    display: flex;
    visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
    opacity: ${({ show }) => (show ? 1 : 0)};

    justify-content: center;
    align-items: center;

    transition: opacity 0.2s ease-in-out;
    white-space: nowrap;
`;

const TooltipText = styled.div`
    ${mix.type({ level: 'body1' })}
    ${mix.color({ profile: 'title', dark: true })}
    text-align: center;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const IntegrationItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const IntegrationStatus = styled.button<{ active: boolean; size: number }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${mix.padding({ padding: [0, 0.2] })}

    width: ${({ size }) => mix.unit({ size: size })};
    max-width: ${({ size }) => mix.unit({ size: size })};
    height: ${({ size }) => mix.unit({ size: size })};
    max-height: ${({ size }) => mix.unit({ size: size })};

    border-radius: ${({ size }) => mix.unit({ size: size / 8 })};
    background: white;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    ${mix.margin({ margin: [1, 0] })};

    border: 1px solid
        ${({ active }) =>
            active
                ? mix.palette({ hue: 'primary', shade: '8' })
                : mix.palette({ hue: 'grey', shade: '9' })};

    & > svg {
        width: 100%;
        ${mix.fill({ hue: 'primary', shade: '6' })}
        align-self: flex-end;
    }
`;

const IntegrationIcon = styled.div<{ status: string }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    height: 40%;
    border-radius: 50%;
    transform: translate(-35%, -35%);
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ status }) =>
        status === 'linked'
            ? mix.bg({ hue: 'primary', shade: '5' })
            : mix.bg({ hue: 'grey', shade: '5' })}
    & > svg {
        width: 50%;
        stroke: white;
        opacity: 1 !important;
    }
`;
