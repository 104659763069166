import { endSnooze, saveAction, startSnooze } from '$state/concerns/patient';
import { useAppDispatch, useAppSelector, useSources } from '$state/hooks';
import { NextActionContext } from '$types';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Buttons } from './Flow/Buttons';
import { Flow } from './Flow/Flow';
import { nodes as getNodes } from '$state/queries/flows';
import { Heading } from '$ui/Flo/Heading';
import { applyPadding, mix } from '$ui/Flo/util';
import { CurrentStatus } from './CurrentStatus';
import { source } from '$state/queries/patient';
import { withState } from '$state/utils';
import { SnoozeButton } from '$ui/Snooze/Button';
import { selectSnooze } from '$state/queries/snooze';
import { SnoozeStatus } from '$state/types/snooze';
import { SnoozeForm } from '$ui/Snooze/Form';
import { Icon } from '$ui/Flo/Icon';
import { SnoozeView } from '$ui/Snooze';
import { usePipelineSettings } from '$ui/Settings/Pipeline/hooks';
import { featureEnabled } from '$state/queries/features';

interface Props {
    id: string;
    stage: string | null;
    date?: string;
    disabled?: boolean;
    withoutSummary?: boolean;
    loading?: boolean;
}

export const NextActions = ({
    id,
    stage,
    date,
    disabled,
    withoutSummary = false,
    loading = false,
}: Props) => {
    const [subflow, setSubflow] = React.useState<string | null>(null);
    const [snoozeOpen, setSnoozeOpen] = React.useState(false);
    const nodes = useAppSelector(getNodes);
    const leadSource = useAppSelector(source(id));
    const leadSources = useSources();
    const dispatch = useAppDispatch();

    const pipelineSettingsEnabled = useAppSelector(
        withState(featureEnabled, 'pipeline-settings'),
    );
    const pipelineSettings = usePipelineSettings();

    const snoozeStatus = useAppSelector(withState(selectSnooze, id));

    const onButtonClicked = (subflow: string) => {
        setSubflow(subflow);
    };

    const onComplete = (context: Partial<NextActionContext>) => {
        dispatch(
            saveAction({ ...context, nextAction: context.nextAction!, id }),
        );
        setSubflow(null);
    };

    return (
        <Wrapper>
            <Summary>
                {!loading && !withoutSummary && (
                    <div style={{ width: '100%' }}>
                        <Heading level="subtitle1" align="left" vSpace={1}>
                            Next Action
                        </Heading>
                        <CurrentStatus date={date} stage={stage} />
                    </div>
                )}
                <Snooze withoutSummary={loading || withoutSummary}>
                    <SnoozeButton
                        onClick={() => setSnoozeOpen(true)}
                        disabled={disabled}
                        state={snoozeStatus?.state}
                    />
                </Snooze>
            </Summary>

            <Buttons
                disabled={disabled}
                onClick={disabled ? () => null : onButtonClicked}
                stage={stage}
            />
            <Overlay open={!!subflow} onClick={() => setSubflow(null)} />
            <FlowContainer open={!!subflow} data-cy="next-actions">
                <FlowModal>
                    {!!subflow && (
                        <Flow
                            nodes={nodes}
                            stage={stage}
                            onComplete={onComplete}
                            onCancel={() => setSubflow(null)}
                            initialNode={subflow}
                            leadSource={leadSource || 'Unknown'}
                            leadSources={leadSources}
                            snooze={snoozeStatus}
                            pipelineSettingsEnabled={pipelineSettingsEnabled}
                            settings={pipelineSettings.data}
                        />
                    )}
                </FlowModal>
            </FlowContainer>
            <SnoozeContainer
                patientId={id}
                open={snoozeOpen}
                onClose={() => setSnoozeOpen(false)}
                snoozeStatus={snoozeStatus}
            />
        </Wrapper>
    );
};

interface SnoozeContainerProps {
    patientId: string;
    open: boolean;
    snoozeStatus?: SnoozeStatus | null;
    onClose: () => void;
}

const SnoozeContainer = (props: SnoozeContainerProps) => {
    const { patientId, open, onClose, snoozeStatus } = props;
    const dispatch = useAppDispatch();

    return (
        <>
            <Overlay open={open} onClick={onClose} />
            <FlowContainer open={open}>
                <CloseButtonContainer>
                    <CloseButton open={open} onClick={onClose}>
                        <Icon icon="X" clickable />
                        Close
                    </CloseButton>
                </CloseButtonContainer>
                <FlowModal>
                    {open && (
                        <FlowModalDiv>
                            {!snoozeStatus && (
                                <SnoozeForm
                                    onSnooze={(snooze) => {
                                        dispatch(
                                            startSnooze({
                                                patientId,
                                                ...snooze,
                                            }),
                                        );
                                        onClose();
                                    }}
                                />
                            )}

                            {snoozeStatus && (
                                <SnoozeView
                                    endsAt={snoozeStatus.ends_at}
                                    comment={snoozeStatus.comment}
                                    state={snoozeStatus.state}
                                    onWakened={() => {
                                        dispatch(endSnooze(patientId));
                                        onClose();
                                    }}
                                />
                            )}
                        </FlowModalDiv>
                    )}
                </FlowModal>
            </FlowContainer>
        </>
    );
};

interface OverlayProps {
    open: boolean;
}

const Overlay = styled.div<OverlayProps>`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    z-index: 3000;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    transition: opacity 0.25s;
    background: rgba(0, 0, 0, 0.1);

    ${({ open }) =>
        open &&
        css`
            opacity: 1;
            pointer-events: auto;
            transition: opacity 0.25s;
        `};
`;

const FlowContainer = styled.div<OverlayProps>`
    transform: scale(0);
    transition: transform 0.25s;
    min-height: 200px;
    transform-origin: bottom center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3001;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ${({ open }) =>
        open &&
        css`
            transform: scale(1);
            transition: transform 0.25s;
        `};
`;

const FlowModal = styled.div`
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`;

const FlowModalDiv = styled.div`
    ${mix.padding({ padding: 4 })};
`;

const CloseButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    ${mix.padding({ padding: 0.5 })};
`;

const CloseButton = styled.div<OverlayProps>`
    ${mix.padding({ padding: [1, 2] })};
    background: white;
    color: var(--gray-600);
    display: flex;
    align-items: center;
    line-height: 1;
    justify-content: center;
    ${mix.gap({ size: 0.5 })};
    border-radius: ${mix.unit({ size: 0.5 })};
    z-index: 1000;

    &:hover {
        cursor: pointer;
    }
`;

const Summary = styled.div`
    ${({ theme }) => applyPadding({ theme, padding: [3, 3, 0, 3] })};
    display: flex;
    ${mix.gap({ size: 2 })};
    justify-content: end;
    position: relative;
    min-height: ${mix.unit({ size: 4.25 })};
    box-sizing: content-box;
`;

const Wrapper = styled.div`
    width: 100%;
    border-top: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    align-self: end;
`;

const Snooze = styled.div<{ withoutSummary: boolean }>`
    display: flex;
    align-items: center;

    ${({ withoutSummary }) =>
        !withoutSummary &&
        css`
            position: absolute;
            top: 22px;
        `};
`;
