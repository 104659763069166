import React from 'react';
import { Shadow } from '$ui/Flo/Shadow';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Separator } from '$ui/Settings/Pipeline/CallbackDelay/Separator';

export const Loading = () => {
    return (
        <LoadingContainer>
            <Shadow rounded height={4} width={'auto'} />
            <Shadow rounded height={4} width={'auto'} />
            <Shadow rounded height={4} width={'auto'} />
            <Separator />
            <Shadow rounded height={4} width={'auto'} />
            <Shadow rounded height={4} width={'auto'} />
            <Shadow rounded height={4} width={'auto'} />
            <Separator />
            <Shadow rounded height={4} width={'auto'} />
        </LoadingContainer>
    );
};

const LoadingContainer = styled.div`
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    ${mix.padding({ padding: [3, 4] })};
    ${mix.gap({ size: 4 })};
`;
