import {
    assign,
    createActor,
    emit,
    fromPromise,
    setup,
    SnapshotFrom,
} from 'xstate5';
import { api, refreshCSRFToken } from '$api';
import { AxiosError } from 'axios';
import { AuthDetails, Credentials } from './types';

const REFRESH_INTERVAL_MS = 15 * 60 * 1000;

export const authMachine = setup({
    types: {
        context: {} as {
            details: AuthDetails | null;
            error: string | null;
        },

        events: {} as
            | ({ type: 'authenticate' } & Credentials & {
                      allowedRedirectUrls: string[];
                  })
            | { type: 'refresh' }
            | { type: 'logout' }
            | { type: 'reset' }
            | { type: 'error'; message: string },

        emitted: {} as
            | { type: 'unauthenticated' }
            | { type: 'authenticated'; details: AuthDetails }
            | { type: 'errored'; message: string },
    },

    actions: {
        clear: assign(() => ({
            details: null,
            error: null,
        })),

        emitUnauthenticatedEvent: emit({ type: 'unauthenticated' }),

        emitAuthenticatedEvent: emit(({ context }) => {
            if (!context.details) {
                throw new Error(
                    'Cannot emit authenticated event when not' +
                        ' authenticated. `context.details` cannot be null when' +
                        ' authenticated',
                );
            }

            return {
                type: 'authenticated',
                details: context.details,
            } as const;
        }),

        emitErroredEvent: emit(({ context }) => {
            if (!context.error) {
                throw new Error(
                    'Cannot emit errored event when not in `errored` state',
                );
            }

            return {
                type: 'errored',
                message: context.error,
            } as const;
        }),
    },

    actors: {
        authenticate: fromPromise<
            AuthDetails,
            Credentials & {
                allowedRedirectUrls: string[];
            }
        >(async ({ input }) => {
            await refreshCSRFToken();

            const { data } = await api.post('/auth/session', {
                email: input.email,
                password: input.password,
            });

            // Redirect to the page they were trying to access
            const queryParameters = new URLSearchParams(window.location.search);
            const redirectTo = queryParameters.get('redirect_to');

            if (redirectTo) {
                try {
                    const allowed = input.allowedRedirectUrls;
                    const redirectUrl = new URL(redirectTo);
                    if (allowed?.some((url) => redirectUrl.hostname === url)) {
                        window.location.href = redirectTo;
                    }
                } catch (e) {
                    console.error('Invalid redirect URL');
                }
            }

            return {
                email: data.email,
                token: data.token,
                payload: data.payload,
            };
        }),

        refresh: fromPromise<{ token: string }>(async () => {
            await refreshCSRFToken();
            const { data } = await api.get('/auth/session');
            return data;
        }),

        logout: fromPromise(async () => {
            await api.delete('/auth/session');
        }),
    },
}).createMachine({
    initial: 'unauthenticated',

    context: {
        details: null,
        error: null,
    },

    states: {
        unauthenticated: {
            entry: [{ type: 'emitUnauthenticatedEvent' }, { type: 'clear' }],
            on: {
                authenticate: 'authenticating',
            },
        },

        authenticating: {
            invoke: {
                id: 'authenticate',
                src: 'authenticate',
                input: ({ event }) => {
                    if (event.type === 'authenticate') {
                        return {
                            email: event.email,
                            password: event.password,
                            allowedRedirectUrls: event.allowedRedirectUrls,
                        };
                    }

                    throw new Error('Authenticate actor requires auth input');
                },
                onDone: {
                    target: 'authenticated',
                    actions: [
                        assign(({ event }) => ({
                            details: {
                                token: event.output.token,
                                email: event.output.email,
                                payload: event.output.payload,
                            },
                        })),
                        { type: 'emitAuthenticatedEvent' },
                    ],
                },
                onError: {
                    target: 'errored',
                    actions: assign(({ event }) => {
                        const error = event.error as AxiosError<{
                            message: string;
                        }>;

                        return {
                            error:
                                error.response?.data?.message ??
                                'unknown_error',
                        };
                    }),
                },
            },
        },

        authenticated: {
            on: {
                logout: 'loggingOut',
                error: 'errored',
                refresh: 'refreshing',
            },
            after: {
                [REFRESH_INTERVAL_MS]: {
                    target: 'refreshing',
                },
            },
        },

        refreshing: {
            invoke: {
                id: 'refresh',
                src: 'refresh',
                onDone: {
                    target: 'authenticated',
                    actions: assign(({ context, event }) => {
                        if (!context.details) {
                            return context;
                        }

                        return {
                            details: {
                                email: context.details.email,
                                token: event.output.token,
                                payload: context.details.payload,
                            },
                        };
                    }),
                },
                onError: {
                    target: 'errored',
                    actions: [
                        assign({
                            error: 'session_expired',
                        }),
                    ],
                },
            },
        },

        errored: {
            entry: [{ type: 'emitErroredEvent' }],
            on: {
                authenticate: 'authenticating',
                reset: 'unauthenticated',
            },
        },

        loggingOut: {
            entry: [{ type: 'emitUnauthenticatedEvent' }],
            invoke: {
                id: 'logging-out',
                src: 'logout',
                onDone: {
                    target: 'unauthenticated',
                    actions: ['clear'],
                },
                onError: {
                    target: 'errored',
                    actions: [
                        assign({
                            error: 'logout_failed',
                        }),
                    ],
                },
            },
        },
    },
});

let actor = createActor(authMachine);

const restoredEncodedState = localStorage.getItem('leadflo__auth_machine');
if (restoredEncodedState) {
    actor = createActor(authMachine, {
        snapshot: JSON.parse(restoredEncodedState),
    });
}

actor.subscribe(() => {
    localStorage.setItem(
        'leadflo__auth_machine',
        JSON.stringify(actor.getPersistedSnapshot()),
    );
});

let started = false;

export function reset() {
    actor = createActor(authMachine);
    actor.start();
}

export function start() {
    if (!started) {
        actor.start();
        actor.send({ type: 'refresh' });
        started = true;
    }
}

export function logout() {
    actor.send({ type: 'logout' });
}

export function login(email: string, password: string) {
    actor.send({
        type: 'authenticate',
        email,
        password,
        allowedRedirectUrls: [],
    });
}

export { actor };

export function authenticated(snapshot?: SnapshotFrom<typeof authMachine>) {
    snapshot ??= actor.getSnapshot();
    return snapshot.matches('authenticated') || snapshot.matches('refreshing');
}
